import { useState } from "react";
import { MdCloudUpload } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebook } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPinterest } from "react-icons/fa";
import TermsAndCondition from "../suspense/TermsAndCondition";
import logo from "../../assets/images/logo.webp";

const Footer = () => {
  const token = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const occupation = localStorage.getItem("occupation");
  const membershipType = localStorage.getItem("memberType");
  const [open, setOpen] = useState(false);

  const handlePublish = () => {
    if (token && occupation !== "Product") {
      navigate("/add-new-project");
    } else if (token && occupation === "Product") {
      navigate("/add-new-product");
    } else {
      navigate("/login");
    }
  };

  const navigateToMagazine = () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      navigate("/magazines");
    } else {
      navigate("/login");
    }
  };

  const navigateToSubscription = () => {
    const token = localStorage.getItem("accessToken");
    const membershipType = localStorage.getItem("memberType");
    if (token && membershipType) {
      navigate("/user-membership");
    } else {
      navigate("/pricing");
    }
  };

  return (
    <>
      <div className="bg-neutral-900 mt-5">
        <div className="lg:px-40">
          <div className="flex justify-center">
            <button
              type="button"
              className="flex items-center mt-5 gap-2 text-gray-50 hover:text-blue-400 border border-gray-50 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-md px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
              onClick={handlePublish}
            >
              <MdCloudUpload className="text-lg" /> Publish
            </button>
          </div>
          <footer className="text-white body-font">
            <div className="container  py-10 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
              <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                <Link
                  to="/"
                  className="flex title-font font-medium items-center md:justify-start justify-center text-white"
                >
                  <img
                    src={logo}
                    alt="logo"
                    className="w-40 md:w-auto h-auto"
                  />
                </Link>

                <p className="mt-2 text-sm text-white">
                  Explore Balarch Magazine for innovative architecture, curated
                  designs, and top products shaping the future.
                </p>
              </div>
              <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                <div className="lg:w-1/3 md:w-1/2 w-full text-white px-4">
                  <h2 className="text-xl font-semibold tracking-widest mb-3">
                    Explore
                  </h2>
                  <nav className="list-none mb-10 cursor-pointer">
                    <li className="mb-5">
                      <Link to="/projects" className="hover:text-blue-400">
                        Projects
                      </Link>
                    </li>
                    <li className="mb-5">
                      <Link to="/professionals" className="hover:text-blue-400">
                        Professionals
                      </Link>
                    </li>
                    <li className="mb-5">
                      <Link
                        to="/product-profile"
                        className="hover:text-blue-400"
                      >
                        Products
                      </Link>
                    </li>
                  </nav>
                </div>
                <div className="lg:w-1/3 md:w-1/2 w-full text-white px-4">
                  <h2 className="text-xl font-semibold tracking-widest mb-3">
                    Marketing
                  </h2>
                  <nav className="list-none mb-10 cursor-pointer">
                    <li className="mb-5">
                      <Link to="" className="hover:text-blue-400">
                        About Us
                      </Link>
                    </li>
                    <li className="mb-5">
                      <p
                        onClick={navigateToSubscription}
                        className="hover:text-blue-400"
                      >
                        Subscriptions
                      </p>
                    </li>
                    <li className="mb-5">
                      <p
                        onClick={
                          membershipType !== "Bronze"
                            ? navigateToMagazine
                            : null
                        }
                        className={` text-white  hover:text-blue-400 h ${
                          membershipType === "Bronze"
                            ? "cursor-not-allowed opacity-50"
                            : ""
                        }`}
                      >
                        Magazine
                      </p>
                    </li>
                  </nav>
                </div>
                <div className="lg:w-1/3 md:w-1/2 w-full text-white px-4">
                  <h2 className="text-xl font-semibold tracking-widest mb-3">
                    Support
                  </h2>
                  <nav className="list-none mb-10 cursor-pointer">
                    <li className="mb-5">
                      <Link to="" className="hover:text-blue-400">
                        FAQs
                      </Link>
                    </li>
                    <li className="mb-5">
                      <Link
                        onClick={() => setOpen(true)}
                        className="hover:text-blue-400"
                      >
                        Terms of Use
                      </Link>
                    </li>
                    <li className="mb-5">
                      <Link to="" className="hover:text-blue-400">
                        Privacy Policy
                      </Link>
                    </li>
                  </nav>
                </div>
              </div>
            </div>
            <hr />
            <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
              <p className="text-white text-sm text-center sm:text-left">
                © Balarch, Inc. 2023. | Studio@bal-arch.com
              </p>
              <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                <Link
                  to="https://www.facebook.com/profile.php?id=61554147914334"
                  target="_blank"
                  className="text-white rounded-full bg-blue-800 p-2"
                >
                  <FaFacebook />
                </Link>
                <Link
                  to="https://x.com/Bal_arch"
                  target="_blank"
                  className="ml-3 text-white rounded-full bg-blue-500 p-2"
                >
                  <FaTwitter />
                </Link>
                <Link
                  to="https://www.instagram.com/bal.arch/"
                  target="_blank"
                  className="ml-3 text-white rounded-full bg-pink-600 p-2"
                >
                  <FaInstagram />
                </Link>
                <Link
                  to="https://www.pinterest.com/0fj0bn3eatq9t272mtf0a4jr58s64r/"
                  target="_blank"
                  className="ml-3 text-white rounded-full bg-red-600 p-2"
                >
                  <FaPinterest />
                </Link>
              </span>
            </div>
          </footer>
        </div>
      </div>
      <TermsAndCondition open={open} setOpen={setOpen} />
    </>
  );
};

export default Footer;
