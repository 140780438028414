import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import ScrollToTop from "./components/_app/ScrollToTop";
import Router from "./routes/Router";
import { useNavigate } from "react-router-dom";

function App() {
  const navigate = useNavigate();

  const parseJwt = (token) => {
    try {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      return null;
    }
  };

  const checkTokenExpiry = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const decodedToken = parseJwt(accessToken);
      const isExpired = new Date() > new Date(decodedToken.exp * 1000);
      return isExpired;
    }
    return true;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (checkTokenExpiry()) {
        localStorage.removeItem("accessToken");
      }
    }, 60000);

    return () => clearInterval(interval);
  }, [navigate]);

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href="/css/main.023314b5.css"
          as="style"
          onLoad="this.onload=null;this.rel='stylesheet'"
        />
        <noscript>{`<link rel="stylesheet" href="/css/main.023314b5.css" />`}</noscript>
      </Helmet>
      <ToastContainer />
      <ScrollToTop />
      <Router />
    </>
  );
}

export default App;
