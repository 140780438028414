import React, {lazy, Suspense} from "react";
import { useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "../components/_app/Layout";

const DashboardLayout = lazy(() => import("../components/dashboard/_app/DashboardLayout"));
const Home = lazy(() => import("../pages/Home"));
const PricingPage = lazy(() => import("../pages/PricingPage"));
const ProductItemPage = lazy(() => import("../pages/ProductItemPage"));
const Productos = lazy(() => import("../pages/Productos"));
const ProfessionalDetailPage = lazy(() => import("../pages/ProfessionalDetailPage"));
const Professionals = lazy(() => import("../pages/Professionals"));
const ProjectDetailPage = lazy(() => import("../pages/ProjectDetailPage"));
const Projects= lazy(() => import("../pages/Projects"));
const ViewMagazine =lazy(() => import("../pages/ViewMagazine"));
const Login =lazy(() => import("../pages/auth/Login"));
const Register = lazy(() => import("../pages/auth/Register"));
const AllMagagine =lazy(() => import("../pages/dashboard/admin-dashboard/AllMagagine"));
const AllProductList = lazy(() => import("../pages/dashboard/admin-dashboard/AllProductList"));
const AllProjectList = lazy(() => import("../pages/dashboard/admin-dashboard/AllProjectList"));
const AllSubmissions = lazy(() => import("../pages/dashboard/admin-dashboard/AllSubmissions"));
const AllTags = lazy(() => import("../pages/dashboard/admin-dashboard/AllTags"));
const AllUserList = lazy(() => import("../pages/dashboard/admin-dashboard/AllUserList"));
const ScamReport = lazy(() => import("../pages/dashboard/admin-dashboard/ScamReport"));
const AddNewProduct = lazy(() => import("../pages/dashboard/user-dashboard/AddNewProduct"));
const AddNewProject = lazy(() => import("../pages/dashboard/user-dashboard/AddNewProject"));
const UserCollaborators = lazy(() => import("../pages/dashboard/user-dashboard/UserCollaborators"));
const UserMembership = lazy(() => import("../pages/dashboard/user-dashboard/UserMembership"));
const UserProductList = lazy(() => import("../pages/dashboard/user-dashboard/UserProductList"));
const UserProfilePage = lazy(() => import("../pages/dashboard/user-dashboard/UserProfilePage"));
const UserProjectsList = lazy(() => import("../pages/dashboard/user-dashboard/UserProjectsList"));
const ProductDetailPage = lazy(() => import("./../pages/ProductDetailPage"));
const Success = lazy(() => import("../pages/payment/Success"));
const Cancel = lazy(() => import("../pages/payment/Cancel"));
const ProtectedElement = lazy(() => import("../components/common/ProtectedElement"));
const Upgrade = lazy(() => import("../pages/payment/Upgrade"));


const Router = () => {
  const [role, setRole] = useState(localStorage.getItem("role"));
  useEffect(() => {
    setRole(localStorage.getItem("role"));
  }, []);

  return (
    <Suspense
    fallback={
      <h1>loading</h1>
    }
  >
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route
        path="/"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route
        path="/projects"
        element={
          <Layout>
            <Projects />
          </Layout>
        }
      />
      <Route
        path="/professionals"
        element={
          <Layout>
            <Professionals />
          </Layout>
        }
      />
      <Route
        path="/product-profile"
        element={
          <Layout>
            <Productos />
          </Layout>
        }
      />
      <Route
        path="/project/:projectId"
        element={
          <Layout>
            <ProjectDetailPage />
          </Layout>
        }
      />
      <Route
        path="/professionals/:UserId"
        element={
          <Layout>
            <ProfessionalDetailPage />
          </Layout>
        }
      />
      <Route
        path="/product-profile/:UserId"
        element={
          <Layout>
            <ProductDetailPage />
          </Layout>
        }
      />
      <Route
        path="/product/:productId"
        element={
          <Layout>
            <ProductItemPage />
          </Layout>
        }
      />

      <Route
        path="/magazines"
        element={
          <Layout>
            <ViewMagazine />
          </Layout>
        }
      />

      <Route
        path="/payment-success"
        element={
          <Layout>
            <Success />
          </Layout>
        }
      />
       <Route
        path="/upgrade"
        element={
          <Layout>
            <Upgrade />
          </Layout>
        }
      />
      <Route
        path="/payment-cancel"
        element={
          <Layout>
            <Cancel />
          </Layout>
        }
      />
      <Route
        path="/all-users"
        element={
          <ProtectedElement requiredRoles="SUPER_ADMIN">
            <DashboardLayout>
              <AllUserList />
            </DashboardLayout>
          </ProtectedElement>
        }
      />
      <Route
        path="/all-projects"
        element={
          <ProtectedElement requiredRoles="SUPER_ADMIN">
            <DashboardLayout>
              <AllProjectList />
            </DashboardLayout>
          </ProtectedElement>
        }
      />

      <Route
        path="/all-products"
        element={
          <ProtectedElement requiredRoles="SUPER_ADMIN">
            <DashboardLayout>
              <AllProductList />
            </DashboardLayout>
          </ProtectedElement>
        }
      />

      <Route
        path="/scam-report"
        element={
          <ProtectedElement requiredRoles="SUPER_ADMIN">
            <DashboardLayout>
              <ScamReport />
            </DashboardLayout>
          </ProtectedElement>
        }
      />

      <Route
        path="/all-submissions"
        element={
          <ProtectedElement requiredRoles="SUPER_ADMIN">
            <DashboardLayout>
              <AllSubmissions />
            </DashboardLayout>
          </ProtectedElement>
        }
      />

      <Route
        path="/tags"
        element={
          <ProtectedElement requiredRoles="SUPER_ADMIN">
            <DashboardLayout>
              <AllTags />
            </DashboardLayout>
          </ProtectedElement>
        }
      />

      <Route
        path="/magzine"
        element={
          <ProtectedElement requiredRoles="SUPER_ADMIN">
            <DashboardLayout>
              <AllMagagine />
            </DashboardLayout>
          </ProtectedElement>
        }
      />

      {/* user dashboard routes*/}

      <Route
        path="/user-profile"
        element={
          <ProtectedElement requiredRoles="USER">
            <DashboardLayout>
              <UserProfilePage />
            </DashboardLayout>
          </ProtectedElement>
        }
      />
      <Route
        path="/user-projects"
        element={
          <ProtectedElement requiredRoles="USER">
            <DashboardLayout>
              <UserProjectsList />
            </DashboardLayout>
          </ProtectedElement>
        }
      />

      <Route
        path="/user-products"
        element={
          <ProtectedElement requiredRoles="USER">
            <DashboardLayout>
              <UserProductList />
            </DashboardLayout>
          </ProtectedElement>
        }
      />

      <Route
        path="/user-collaborators"
        element={
          <ProtectedElement requiredRoles="USER">
            <DashboardLayout>
              <UserCollaborators />
            </DashboardLayout>
          </ProtectedElement>
        }
      />

      <Route
        path="/user-membership"
        element={
          <ProtectedElement requiredRoles="USER">
            <DashboardLayout>
              <UserMembership />
            </DashboardLayout>
          </ProtectedElement>
        }
      />

      <Route
        path="/add-new-project"
        element={
          <ProtectedElement requiredRoles="USER">
            <DashboardLayout>
              <AddNewProject />
            </DashboardLayout>
          </ProtectedElement>
        }
      />

      <Route
        path="/add-new-product"
        element={
          <ProtectedElement requiredRoles="USER">
            <DashboardLayout>
              <AddNewProduct />
            </DashboardLayout>
          </ProtectedElement>
        }
      />
    </Routes>
    </Suspense>
  );
};

export default Router;
