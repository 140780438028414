import axios from "axios";

const BASE_URL = `${process.env.REACT_APP_BASE_URL}`;

export const getAllProjects = async (page, size, filters) => {
  try {
    let url = `${BASE_URL}api/getAll?page=${page}&size=${size}`;

    if (filters) {
      for (const key in filters) {
        if (filters[key] !== null) {
          url += `&${key}=${encodeURIComponent(filters[key])}`;
        }
      }
    }

    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch projects");
  }
};

export const getAllProfessionals = async (page, size, filters) => {
  try {
    let url = `${BASE_URL}api/signup/getAllUsers?page=${page}&size=${size}`;

    if (filters) {
      for (const key in filters) {
        if (filters[key] !== null) {
          url += `&${key}=${filters[key]}`;
        }
      }
    }

    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch professional users");
  }
};

export const getAllProductsProfile = async (page, size, filters) => {
  try {
    let url = `${BASE_URL}api/products/get-product-users?page=${page}&size=${size}`;

    if (filters) { 
      for (const key in filters) {
        if (filters[key] !== null) {
          url += `&${key}=${encodeURIComponent(filters[key])}`;
        }
      }
    }

    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch product");
  }
};

export const getProjectById = async (projectId, token) => {
  try {
    const response = await fetch(`${BASE_URL}api/getByProjectId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ProjectId: projectId }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch project details");
    }

    const data = await response.json();
    return data.Result;
  } catch (error) {
    throw new Error("Error fetching project details: " + error.message);
  }
};

export const getProfessionalById = async (userId, token) => {
  try {
    const response = await fetch(`${BASE_URL}api/signup/getByUserId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ UserId: userId }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch professional details");
    }

    const data = await response.json();
    return data.Result;
  } catch (error) {
    throw new Error("Error fetching professional details: " + error.message);
  }
};

export const getProductById = async (productId, token) => {
  try {
    const response = await fetch(`${BASE_URL}api/products/getByProductId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ProductId: productId }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch product details");
    }

    const data = await response.json();
    return data.Result;
  } catch (error) {
    throw new Error("Error fetching product details: " + error.message);
  }
};

export const getAllCountries = async () => {
  try {
    const response = await axios.get(`${BASE_URL}api/getAllCountries`);

    return response.data.Result;
  } catch (error) {
    throw new Error("Failed to fetch countries");
  }
};

export const getAllArchitect = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}api/signup/user-profile-names`
    );

    return response.data.Result;
  } catch (error) {
    throw new Error("Failed to fetch architects");
  }
};

export const requestCollab = async (
  requestData,
  userId,
  token,
  collabId,
  projectId,
  projectName,
  userName,
  collabName,
  picture
) => {
  try {
    const response = await axios.post(
      `${BASE_URL}api/signup/collaborationReq`,
      {
        ...requestData,
        ProjectId: projectId,
        ProjectName: projectName,
        Collobarator: {
          CollabarotorId: collabId,
          CollabarotorName: collabName,
        },
        User: { UserId: userId, UserName: userName },
        ProfilePicture: picture,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data.Result;
  } catch (error) {
    throw new Error(error.response.data.message || "Something went wrong");
  }
};

export const requestScamReport = async (reportData, token) => {
  try {
    const response = await axios.post(
      `${BASE_URL}api/scamReport`,
      {
        ...reportData,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || "Something went wrong");
  }
};

export const getCollabarationProjects = async (userId, token) => {
  try {
    const response = await fetch(`${BASE_URL}api/signup/getCollaboratorId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ UserId: userId }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch collabaration project");
    }

    const data = await response.json();
    return data.Result;
  } catch (error) {
    throw new Error("Error fetching collabaration projects: " + error.message);
  }
};

export const getAllSubscriptionPlans = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}api/get-all-membership-subscription`
    );

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch subscription plans");
  }
};

export const getBannerImages = async () => {
  try {
    const response = await axios.get(`${BASE_URL}api/getProjectByMembership`);

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch banner images");
  }
};

export const getTopProjects = async (page, size) => {
  try {
    const response = await axios.get(
      `${BASE_URL}api/getProjectByCreatedTime?page=${page}&size=${size}`
    );

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch banner images");
  }
};

export const getAllSponserUsers = async () => {
  try {
    const response = await axios.get(`${BASE_URL}api/signup/get-all-sponsors`);

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch sponsers");
  }
};

export const getAllProductSponsers = async () => {
  try {
    const response = await axios.get(
      `${BASE_URL}api/signup/get-all-product-users-sponsors`
    );

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch sponsers");
  }
};

export const getDistinctYear = async () => {
  try {
    const response = await axios.get(`${BASE_URL}api/magazine/get/years`);

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch sponsers");
  }
};

export const getDistinctMonth = async (year) => {
  try {
    const response = await axios.get(
      `${BASE_URL}api/magazine/get/months?year=${year}`
    );

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch sponsers");
  }
};

export const getSubscriptionStatus = async (userId, token) => {
  try {
    const response = await axios.get(
      `https://database.bal-arch.com/api/signup/count-details?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch status");
  }
};


