import { useState, useContext, Fragment } from "react";
import { FaBars } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { Menu, Transition } from "@headlessui/react";
import bronze from "../../assets/images/pricing-logo/bronze.webp";
import copper from "../../assets/images/pricing-logo/copper.webp";
import gold from "../../assets/images/pricing-logo/gold.webp";
import silver from "../../assets/images/pricing-logo/silver.webp";
import platinum from "../../assets/images/pricing-logo/platinum.webp";
import titanium from "../../assets/images/pricing-logo/titanium.webp";
import logo from "../../assets/images/logo.webp";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { handleLogout } = useContext(AppContext);

  const token = localStorage.getItem("accessToken");
  const name = localStorage.getItem("name");
  const role = localStorage.getItem("role");
  const profilePicture = localStorage.getItem("profilePicture");
  const occupation = localStorage.getItem("occupation");
  const membershipType = localStorage.getItem("memberType");

  const membershipLogos = {
    Bronze: bronze,
    Copper: copper,
    Gold: gold,
    Silver: silver,
    Platinum: platinum,
    Titanium: titanium,
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const navigateToDashboard = () => {
    if (role === "SUPER_ADMIN") {
      navigate("/all-users");
    } else {
      navigate("/user-profile");
    }
  };

  const navigateToMagazine = () => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      navigate("/magazines");
    } else {
      navigate("/login");
    }
  };

  const navigateToPublish = () => {
    if (occupation === "Product") {
      navigate("/add-new-product");
    } else {
      navigate("/add-new-project");
    }
  };

  const navigateToSuperAdminScreen = () => {
    navigate("/all-users");
  };

  return (
    <div>
      {/* Desktop Navbar */}
      <div className="hidden sm:flex top-0 z-50 h-16 items-center gap-6 justify-end bg-neutral-900 text-sm font-normal text-gray-200 sm:px-6 lg:px-40">
        <div className="flex items-center gap-6">
          <button
            type="button"
            className="px-3 py-2 text-sm font-medium text-white focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 cursor-not-allowed opacity-50"
          >
            Book
          </button>
          <button
            type="button"
            className={`px-3 py-2 text-sm font-medium text-white focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 ${
              membershipType === "Bronze" ? "cursor-not-allowed opacity-50" : ""
            }`}
            onClick={membershipType !== "Bronze" ? navigateToMagazine : null}
            disabled={membershipType === "Bronze"}
          >
            Magazine
          </button>

          {token ? (
            <>
              <div className="flex items-center gap-2 relative">
                <button
                  type="button"
                  className="px-3 py-2 text-sm font-medium text-white focus:outline-none rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  onClick={navigateToDashboard}
                >
                  Dashboard
                </button>

                <div className="flex items-center gap-4">
                  <Menu as="div" className="relative">
                    <Menu.Button>
                      <img
                        className="relative inline-block h-10 w-10 rounded-full object-cover object-center cursor-pointer"
                        src={profilePicture}
                        alt="Rounded avatar"
                      />
                    </Menu.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 text-gray-800 origin-top-right rounded-md bg-white py-1 shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm font-semibold text-gray-700"
                              )}
                            >
                              Welcome, {name}
                            </Link>
                          )}
                        </Menu.Item>
                        {role === "USER" && (
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                <div className="flex items-center">
                                  <img
                                    src={membershipLogos[membershipType]}
                                    alt={`${membershipType} logo`}
                                    className="h-4 w-4 mr-2"
                                  />
                                  {membershipType}
                                </div>
                              </Link>
                            )}
                          </Menu.Item>
                        )}

                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-red-500 font-semibold w-full text-left"
                              )}
                              onClick={handleLogout}
                            >
                              <IoMdLogOut className="inline-block mr-2" />
                              Sign Out
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </>
          ) : (
            <button
              type="button"
              className="px-3 py-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-gray-800 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              onClick={() => navigate("/login")}
            >
              Log In
            </button>
          )}
        </div>
      </div>

      {/* Mobile Navbar */}
      <div className="sm:hidden px-4 py-2 flex justify-between items-center bg-neutral-900 text-white">
        <Link to="/">
          <img src={logo} className="w-20" alt="logo-balarch" />
        </Link>
        <FaBars
          className="text-2xl cursor-pointer"
          onClick={toggleMobileMenu}
        />
      </div>

      {/* Mobile Menu Popup */}
      {isMobileMenuOpen && (
        <div className="sm:hidden fixed inset-0 bg-gray-900 bg-opacity-75 z-50">
          <div className="flex flex-col items-center justify-center h-full">
            <NavLink
              to="/projects"
              className="text-gray-200 text-xl py-2"
              onClick={toggleMobileMenu}
            >
              PROJECTS
            </NavLink>
            <NavLink
              to="/professionals"
              className="text-gray-200 text-xl py-2"
              onClick={toggleMobileMenu}
            >
              PROFESSIONALS
            </NavLink>
            <NavLink
              to="/product-profile"
              className="text-gray-200 text-xl py-2"
              onClick={toggleMobileMenu}
            >
              PRODUCTS
            </NavLink>
            {token ? (
              role === "SUPER_ADMIN" ? (
                <button
                  type="button"
                  className="text-gray-200 text-xl py-2"
                  onClick={() => {
                    toggleMobileMenu();
                    navigateToSuperAdminScreen();
                  }}
                >
                  PUBLISH
                </button>
              ) : (
                <button
                  type="button"
                  className="text-gray-200 text-xl py-2"
                  onClick={() => {
                    toggleMobileMenu();
                    navigateToPublish();
                  }}
                >
                  PUBLISH
                </button>
              )
            ) : (
              <Link
                to="/login"
                className="text-gray-200 text-xl py-2"
                onClick={toggleMobileMenu}
              >
                PUBLISH
              </Link>
            )}
          </div>
        </div>
      )}

      {/* Desktop Navigation Links */}
      <div className="py-5 flex items-center justify-center text-gray-900 font-semibold gap-32 hidden sm:flex">
        <NavLink
          to="/projects"
          className={({ isActive }) =>
            isActive
              ? "underline underline-offset-8"
              : "hover:underline hover:underline-offset-8"
          }
        >
          PROJECTS
        </NavLink>
        <NavLink
          to="/professionals"
          className={({ isActive }) =>
            isActive
              ? "underline underline-offset-8"
              : "hover:underline hover:underline-offset-8"
          }
        >
          PROFESSIONALS
        </NavLink>

        <Link to="/">
          <img src={logo} className="w-40" alt="logo-balarch" />
        </Link>
        <NavLink
          to="/product-profile"
          className={({ isActive }) =>
            isActive
              ? "underline underline-offset-8"
              : "hover:underline hover:underline-offset-8"
          }
        >
          PRODUCTS
        </NavLink>

        {token ? (
          role === "SUPER_ADMIN" ? (
            <button
              type="button"
              className="hover:underline hover:underline-offset-8"
              onClick={navigateToSuperAdminScreen}
            >
              PUBLISH
            </button>
          ) : (
            <button
              type="button"
              className="hover:underline hover:underline-offset-8"
              onClick={navigateToPublish}
            >
              PUBLISH
            </button>
          )
        ) : (
          <Link
            to="/login"
            className="hover:underline hover:underline-offset-8"
          >
            PUBLISH
          </Link>
        )}
      </div>
    </div>
  );
};
