import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";

export default function TermsAndCondition({ open, setOpen }) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base text-center font-semibold leading-6 text-gray-900"
                      >
                        Terms and Condition
                      </Dialog.Title>
                      <div className="mt-2">
                        <div>
                          <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                            <div className="sm:flex sm:items-start">
                              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500 leading-6">
                                    Version 1.0, Effective as of June 1, 2024
                                    <br />
                                    <span className="font-semibold mt-2 underline">
                                      Terms and Conditions Agreement-
                                    </span>
                                    <br />
                                    Welcome to Balarch LLC ("Balarch"), where we
                                    offer premier digital platforms and services
                                    for showcasing architectural projects. This
                                    document, our Terms and Conditions (the
                                    "Terms"), establishes the legal framework
                                    for your interaction with Balarch,
                                    signifying your agreement to comply with
                                    these provisions in your use of our
                                    services.
                                    <br />
                                    <span className="font-semibold mt-2 underline">
                                      Acceptance of Terms-
                                    </span>
                                    <br />
                                    By accessing Balarch’s digital offerings,
                                    you are entering into a binding contract
                                    with us, under which you agree to adhere to
                                    these Terms, along with any additional terms
                                    applicable to certain services we may offer,
                                    each forming an integral part of your
                                    agreement with Balarch.
                                    <span className="font-semibold mt-2 underline">
                                      Specific Service Terms-
                                    </span>
                                    <br />
                                    While these general Terms govern your
                                    overall use of Balarch’s services, specific
                                    offerings may come with their own terms,
                                    which will take precedence in case of any
                                    discrepancy with the general Terms.
                                    <br />
                                    <span className="font-semibold mt-2 underline">
                                      Intellectual Property and Submissions-
                                    </span>
                                    <br />
                                    Balarch LLC owns or licenses all content,
                                    trademarks, and other intellectual property
                                    on our platforms. Your use does not transfer
                                    any rights to you, and commercial use
                                    without our express permission is
                                    prohibited. When you submit projects to
                                    Balarch, you grant us a worldwide, perpetual
                                    license to use, modify, and distribute your
                                    content in our publications and platforms,
                                    aiming to promote architectural excellence.
                                    <br />
                                    <span className="font-semibold mt-2 underline">
                                      Limitation of Liability-
                                    </span>
                                    <br />
                                    Balarch endeavors to ensure the reliability
                                    and accessibility of our services but does
                                    not guarantee uninterrupted access. Your use
                                    of Balarch’s services is at your own risk.
                                    We disclaim all warranties to the extent
                                    permitted by law and limit our liability to
                                    direct damages caused by our gross
                                    negligence or intentional misconduct.
                                    <br />
                                    <span className="font-semibold mt-2 underline">
                                      User Responsibility and Balarch’s
                                      Discretion-
                                    </span>
                                    <br />
                                    You are responsible for your submissions and
                                    adherence to these Terms. Balarch reserves
                                    the right, without obligation, to monitor
                                    compliance and to refuse or remove content
                                    or memberships that do not align with our
                                    vision or these Terms. This right is
                                    exercised at Balarch's discretion, aimed at
                                    maintaining the integrity of our platform
                                    without entailing a lengthy review process
                                    or legal complications.
                                    <br />
                                    <span className="font-semibold mt-2 underline">
                                      Amendments and Jurisdiction-
                                    </span>
                                    <br />
                                    These Terms may be updated by Balarch, with
                                    changes taking effect upon their posting. It
                                    is your responsibility to stay informed
                                    about the current Terms. Any disputes will
                                    be governed by the laws of the United States
                                    and resolved within the jurisdiction where
                                    Balarch LLC is registered.
                                    <span className="font-semibold mt-2 underline">
                                      {" "}
                                      Comprehensive Agreement-
                                    </span>
                                    <br />
                                    This agreement, encompassing the Terms
                                    outlined herein and any service-specific
                                    terms, constitutes the entire understanding
                                    between you and Balarch LLC regarding your
                                    use of our services. By submitting your
                                    architectural projects, you acknowledge and
                                    consent to these terms, ensuring our mutual
                                    commitment to promoting architectural
                                    innovation and excellence.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
